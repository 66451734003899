import React, { useEffect, useMemo } from "react";

import { OnlineModifications } from "Types";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { Box, Flex } from "Atoms";
import { getTextColor } from "Utils";
import { getSingleModificationPriceText } from "PriceUtils";
import { useQoplaStore } from "Stores";
import { SingleOption } from "./SingleOption";
import {
    getPreSelectedSimpleMods,
    productHasSimpleFlavourMod,
    productHasSimpleSizeMod
} from "../../../Utils/expressProductUtils";
import { ExpressMiniScrollBar } from "../../shared/ExpressScrollBar";

type Props = {
    modificationsToUse: OnlineModifications.ModificationsToUse;
    selectedModifications: OnlineModifications.SelectedModifications | null;
    isPortrait: boolean;
    isBundleItem: boolean;
    setModificationOption: (opt: OnlineModifications.SelectedModifications) => void;
};

export const ProductModifications: React.FC<Props> = ({
    modificationsToUse,
    isPortrait,
    isBundleItem,
    setModificationOption,
    selectedModifications
}) => {
    /** Was causing render issues added this here to prevent it */
    const stableModificationsToUse = useMemo(
        () => modificationsToUse,
        [modificationsToUse?.sizes, modificationsToUse?.flavours]
    );

    const { colors } = useTheme();
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const optionTextColor = getTextColor(colors.expressSecondary);
    const { sizes, flavours } = modificationsToUse || { sizes: null, flavours: null };

    const onSetModifications = (
        key: OnlineModifications.ModificationKeys,
        option: OnlineModifications.ModificationOption
    ) => {
        setModificationOption({
            ...selectedModifications,
            [key]: option
        });
    };

    useEffect(() => {
        if (
            !!modificationsToUse &&
            !selectedModifications &&
            (productHasSimpleFlavourMod(modificationsToUse) || productHasSimpleSizeMod(modificationsToUse))
        ) {
            setModificationOption(getPreSelectedSimpleMods(modificationsToUse));
        }
    }, [stableModificationsToUse]);

    const sizeCount = sizes?.length || 0;
    const flavourCount = flavours?.length || 0;
    const totalNumberOfOptions = sizeCount + flavourCount;

    const showSizeHeader = !!sizes?.length && flavourCount > 0;
    const showFlavourHeader = !!flavours?.length && sizeCount > 0;

    return (
        <Flex
            w="100%"
            h={isPortrait ? "300px" : "260px"}
            bg={colors.expressSecondary}
            borderRadius={"17px 17px 0 0"}
            alignItems={"center"}
            justifyContent={"center"}
            color={colors.expressPrimary}
            fontSize={"xl"}
            position={"absolute"}
            top={"0"}
            left={"0"}
            mb={10}
            zIndex={1}
        >
            <ExpressMiniScrollBar
                width={"100%"}
                direction={"column"}
                px={isPortrait ? 8 : 6}
                height={"100%"}
                pt={2}
                overflowY={"auto"}
                paddingBottom={totalNumberOfOptions > 4 ? "2rem" : "0"}
            >
                {showSizeHeader && (
                    <Box
                        color={optionTextColor}
                        fontSize={isPortrait ? "md" : "xs"}
                        fontWeight={"600"}
                        whiteSpace={"nowrap"}
                        pb={1}
                    >
                        {translate("size")}
                    </Box>
                )}
                <Box pt={showSizeHeader ? 0 : 1}>
                    {sizes?.map(val => {
                        const isSelected = val.name === selectedModifications?.sizes?.name;
                        const hidePriceForBundle = val.price > 0 && isBundleItem;
                        const priceText = !hidePriceForBundle
                            ? getSingleModificationPriceText(val.addonPrice, val.price, companyLocale)
                            : "";
                        return (
                            <Flex
                                key={val.name}
                                onClick={() => onSetModifications("sizes", val)}
                                h="auto"
                                mb={3}
                                w="100%"
                            >
                                <SingleOption
                                    isPortrait={isPortrait}
                                    isSelected={isSelected}
                                    optionTextColor={optionTextColor}
                                    name={val.name}
                                    priceText={priceText}
                                />
                            </Flex>
                        );
                    })}
                </Box>

                {showFlavourHeader && (
                    <Box
                        color={optionTextColor}
                        fontSize={isPortrait ? "md" : "xs"}
                        fontWeight={"600"}
                        whiteSpace={"nowrap"}
                        pb={1}
                    >
                        {translate("flavours")}
                    </Box>
                )}
                <Box pt={showFlavourHeader ? 0 : 1}>
                    {flavours?.map(val => {
                        const isSelected = val.name === selectedModifications?.flavours?.name;
                        const hidePriceForBundle = val.price > 0 && isBundleItem;
                        const priceText = !hidePriceForBundle
                            ? getSingleModificationPriceText(val.addonPrice, val.price, companyLocale)
                            : "";
                        return (
                            <Flex
                                key={val.name}
                                onClick={() => onSetModifications("flavours", val)}
                                h="auto"
                                mb={3}
                                w="100%"
                            >
                                <SingleOption
                                    isPortrait={isPortrait}
                                    isSelected={isSelected}
                                    optionTextColor={optionTextColor}
                                    name={val.name}
                                    priceText={priceText}
                                />
                            </Flex>
                        );
                    })}
                </Box>
            </ExpressMiniScrollBar>
        </Flex>
    );
};
