import React, { useState, useEffect, useMemo } from "react";

import {
    RefProduct,
    MenuBundleProduct,
    MenuProduct,
    OnlineProduct,
    CartProduct,
    AddonsHashMapValue,
    OnlineModifications,
    Modification
} from "Types";
import { Box } from "Atoms";
import { useDrawer } from "Providers";
import { useTheme } from "ThemeProvider";
import { useLanguage } from "LanguageProvider";
import { useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import {
    getPriceStringForMenuProductWithLocale,
    getPriceStringForMenuBundleProductWithLocale,
    getPriceStringForProductWithLocale
} from "PriceUtils";
import { hasBundleModificationPrice } from "../../../../../admin/components/poses/pos/utils";
import {
    ProductImage,
    ProductCardFooter,
    ProductCardFooterSelected,
    BundleCategory,
    ProductCardFooterSimpleBundle,
    ProductCardFooterBundleItem,
    ProductCardAnimation,
    ProductCardFooterSimpleMods,
    ProductModifications
} from "./components";
import { ExpressProductInformationBanner } from "./ExpressProductInformationBanner";
import { tempBundleProductOrderProduct } from "../../../poses/shared/utils/cartController";
import {
    checkIsSimpleBundleProduct,
    isMenuBundleProduct,
    bundleItemsHaveExtraMods,
    isMenuProduct,
    getMenuProductPriceWithModifications,
    getPreSelectedSimpleMods,
    productHasSimpleMods,
    getRequiredDefaultModifications
} from "../../Utils/expressProductUtils";

export type ProductToAdd = RefProduct | MenuProduct | MenuBundleProduct;

type Props = {
    product: OnlineProduct;
    selectedProductId: string | null;
    setSelectedProductId: (id: string | null) => void;
    addProduct?: (
        product: OnlineProduct,
        addToCartAnimation?: () => void,
        isSimpleBundle?: boolean,
        isEditingSimpleBundle?: boolean,
        modification?: Modification
    ) => void;
    openProductPage?: (productAddons?: AddonsHashMapValue, isBundleProduct?: boolean) => void;
    simpleBundleIdToEdit?: string | null;
    hasAddons?: boolean;
    isOutOfStock?: boolean;
    bundleItemProps?: {
        isBundleItem: boolean;
        handleSelectBundleItem?: (product: OnlineProduct) => void;
    };
    modificationProps?: {
        isModifyMode?: boolean;
        modsInModifyMode?: Modification | null;
        modificationProductIdToEdit?: string | null;
        setModificationProductIdToEdit?: (id: string | null) => void;
        handleMustChooseModifications?: (hasSingleMods: boolean) => void;
        handleSelectModifications?: (modifications: Modification, hasFinished: boolean) => void;
    };
};

export const ExpressProductCard: React.FC<Props> = ({
    product,
    selectedProductId,
    setSelectedProductId,
    addProduct,
    openProductPage,
    isOutOfStock = false,
    simpleBundleIdToEdit,
    bundleItemProps,
    modificationProps
}) => {
    const { selectedShop } = useQoplaStore();
    const [wasAdded, setWasAdded] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);
    const [isBundleCategoryOpen, setIsBundleCategoryOpen] = useState(false);
    const [selectedBundleItemId, setSelectedBundleItemId] = useState<string>("");

    const [openModifications, setOpenModifications] = useState(false);
    const [selectedModifications, setSelectedModifications] =
        useState<OnlineModifications.SelectedModifications | null>(null);
    const [selectedProductModId, setSelectedProductModId] = useState<string | null>(null);

    const { translate } = useLanguage();
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { refProducts, addons } = usePosStore();
    const { onOpenDrawer } = useDrawer();
    const {
        selectedBundleCartProduct,
        setSelectedBundleCartProduct,
        selectedCartProductWithMods,
        setSelectedCartProductWithMods,
        removeCartProduct
    } = useOrderWindowsStore();

    const { modifications, requiredMods } = getRequiredDefaultModifications(product);

    const isSelected = selectedProductId === product.id;
    const isEditingSimpleBundle = simpleBundleIdToEdit === product.id;
    const selectedModProductInEdit = product.id === selectedCartProductWithMods?.menuProduct?.id;

    const {
        modificationProductIdToEdit,
        setModificationProductIdToEdit,
        handleMustChooseModifications,
        handleSelectModifications,
        isModifyMode = false,
        modsInModifyMode
    } = modificationProps || {};

    const { isBundleItem = false, handleSelectBundleItem } = bundleItemProps || {};

    useEffect(() => {
        if (isOutOfStock && isSelected) {
            setSelectedProductId(null);
        }
    }, [isOutOfStock]);

    /** In order to go back in bundle creator to a bundle item with mods! */
    useEffect(() => {
        if (
            isModifyMode === true &&
            isSelected &&
            selectedProductId === modificationProductIdToEdit &&
            !!modifications &&
            !!modsInModifyMode
        ) {
            setSelectedProductModId(modificationProductIdToEdit);
            const hasSingleMods = productHasSimpleMods(modifications);
            setOpenModifications(!hasSingleMods);
            setSelectedModifications(modsInModifyMode as OnlineModifications.SelectedModifications);
        }
    }, [isModifyMode === true]);

    useEffect(() => {
        const isNewProductSelected =
            !!selectedProductId && !!selectedBundleCartProduct?.menuBundleProduct?.id
                ? selectedProductId !== selectedBundleCartProduct?.menuBundleProduct?.id
                : false;
        const isCurrentProductSelected = selectedProductId === product.id;

        if (!!simpleBundleIdToEdit && !isNewProductSelected && isCurrentProductSelected) {
            setIsBundleCategoryOpen(true);
            if (selectedBundleCartProduct?.orderProduct?.selectedBundleProductItems?.length) {
                setSelectedBundleItemId(
                    selectedBundleCartProduct.orderProduct.selectedBundleProductItems[0].refProductId ?? ""
                );
            }
        }

        if (isNewProductSelected && !isBundleItem) {
            setSelectedBundleCartProduct(null);
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
        }

        if (simpleBundleIdToEdit !== product.id) {
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
        }

        if (!!modificationProductIdToEdit && product.id !== modificationProductIdToEdit) {
            setOpenModifications(false);
            setSelectedProductModId(null);
        }

        if (
            !!modificationProductIdToEdit &&
            selectedProductId === modificationProductIdToEdit &&
            !!selectedCartProductWithMods &&
            selectedProductId === product.id
        ) {
            setSelectedProductModId(modificationProductIdToEdit);
            setOpenModifications(true);
            setSelectedModifications(
                selectedCartProductWithMods?.orderProduct.modifications as OnlineModifications.SelectedModifications
            );
        }

        if (selectedProductId !== product.id) {
            setOpenModifications(false);
            setSelectedProductModId(null);
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
            setSelectedModifications(null);
        }
    }, [selectedProductId, simpleBundleIdToEdit, modificationProductIdToEdit]);

    const isBundleProduct = "refBundleProduct" in product && !!product.refBundleProduct;

    const addToCartAnimation = () => {
        setStartAnimation(true);
        setTimeout(() => {
            setStartAnimation(false);
        }, 1000);
    };

    const showMessageAddedProduct = () => {
        setWasAdded(true);
        setTimeout(() => {
            setWasAdded(false);
        }, 600);
    };

    const handleAddSimpleBundleProduct = () => {
        if (selectedBundleItemId && isBundleCategoryOpen) {
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
            showMessageAddedProduct();
            addProduct && addProduct(product, addToCartAnimation, true, isEditingSimpleBundle);
        } else {
            const tempBundleProduct = tempBundleProductOrderProduct(
                product.menuBundleProduct as MenuBundleProduct,
                selectedShop?.id!,
                product.id,
                1,
                null,
                null
            );
            setIsBundleCategoryOpen(true);
            setSelectedBundleCartProduct(tempBundleProduct as CartProduct);
        }
    };

    const handleBundleItem = () => {
        if (!!handleSelectBundleItem) {
            handleSelectBundleItem(product);
            setSelectedProductId(null);
        }
    };

    const { productToDisplay, productPriceWithCurrency, hasSingleMods, prioModsOverAddons } = useMemo(() => {
        let productToDisplay = null;
        let productPriceWithCurrency = "";
        let hasSingleMods = false;
        let prioModsOverAddons = false;
        const isAMenuProduct = isMenuProduct(product);
        const isAMenuBundleProduct = isMenuBundleProduct(product);

        if (isAMenuProduct && !modifications) {
            productToDisplay = product.refProduct as RefProduct;
            productPriceWithCurrency = getPriceStringForMenuProductWithLocale(product.menuProduct, companyLocale);
        } else if (isAMenuBundleProduct) {
            productToDisplay = product.refBundleProduct;
            const hasModPrice = hasBundleModificationPrice(product, refProducts);
            const extraText = hasModPrice ? `${translate("from")} ` : "";
            productPriceWithCurrency = getPriceStringForMenuBundleProductWithLocale(
                product.menuBundleProduct!,
                companyLocale,
                extraText
            );
        } else if (isBundleItem) {
            productToDisplay = product.refProduct as RefProduct;
            if (!!modifications) {
                const { hasStartingPrice, priceText, mustChooseOptions } = getMenuProductPriceWithModifications(
                    productToDisplay.defaultPrice,
                    productToDisplay.priceType,
                    isBundleItem,
                    modifications,
                    companyLocale
                );
                hasSingleMods = !mustChooseOptions;
                productPriceWithCurrency = hasStartingPrice ? `${translate("from")} ${priceText}` : priceText;
            }
        } else if (isAMenuProduct && !!modifications) {
            productToDisplay = product.refProduct as RefProduct;
            const { hasStartingPrice, priceText, mustChooseOptions } = getMenuProductPriceWithModifications(
                productToDisplay.defaultPrice,
                productToDisplay.priceType,
                false,
                modifications,
                companyLocale
            );
            const productHasAddons = !!addons[productToDisplay.id];
            hasSingleMods = !mustChooseOptions;
            prioModsOverAddons = productHasAddons && !hasSingleMods;
            productPriceWithCurrency = hasStartingPrice ? `${translate("from")} ${priceText}` : priceText;
        } else {
            productToDisplay = product.refProduct || product.refBundleProduct;
            productPriceWithCurrency = getPriceStringForProductWithLocale(
                productToDisplay!,
                companyLocale
                // skipDecimalsInProductCard
            );
        }
        return { productToDisplay, productPriceWithCurrency, hasSingleMods, prioModsOverAddons };
    }, [product]);

    const productAddons = productToDisplay && productToDisplay.id ? addons[productToDisplay.id] || [] : [];
    const hasAddons = prioModsOverAddons ? false : !!productAddons.length;

    /** Simple product with mods & no addons!  */
    const isSimpleProductWithManyMods = !isBundleItem && !!modifications && !hasAddons;
    const isBundleItemWithManyMods = isBundleItem && !!modifications;

    let isSimpleBundle = false;
    if (isBundleProduct && product.refBundleProduct) {
        isSimpleBundle = checkIsSimpleBundleProduct(product.refBundleProduct, hasAddons);
        if (isSimpleBundle) {
            /** If any of the simple bundle has a option that needs to have mods selected then go to bundle creator! */
            const hasExtraMods = bundleItemsHaveExtraMods(
                product.refBundleProduct.bundleProductCategories[0].refProductIdList,
                refProducts
            );
            isSimpleBundle = !hasExtraMods;
        }
    }

    if (!productToDisplay) {
        return null;
    }

    const haveModificationsBeenSelected = (opt?: OnlineModifications.SelectedModifications) => {
        if (!opt) {
            const sizeIsSelected = !!selectedModifications?.sizes;
            const flavourIsSelected = !!selectedModifications?.flavours;

            const isSizeRequired = requiredMods.sizeIsRequired ? !sizeIsSelected : false;
            const isFlavourRequired = requiredMods.flavourIsRequired ? !flavourIsSelected : false;
            return !isSizeRequired && !isFlavourRequired;
        } else {
            const sizeIsSelected = !!opt.sizes;
            const flavourIsSelected = !!opt.flavours;

            const isSizeRequired = requiredMods.sizeIsRequired ? !sizeIsSelected : false;
            const isFlavourRequired = requiredMods.flavourIsRequired ? !flavourIsSelected : false;
            return !isSizeRequired && !isFlavourRequired;
        }
    };

    const onHandleProductModifications = (selectedProductId: string) => {
        const modificationsFinished = haveModificationsBeenSelected();
        const canAddProductWithMods =
            (openModifications || hasSingleMods) &&
            !!selectedProductModId &&
            !!selectedModifications &&
            modificationsFinished;

        const canAddProductWithModsAfterModify =
            hasSingleMods && modificationsFinished && !!selectedProductId && !!selectedModifications;

        if (canAddProductWithMods || canAddProductWithModsAfterModify) {
            const modsToUse = selectedModifications as Modification;
            if (!isBundleItem) {
                !!modificationProductIdToEdit &&
                    selectedCartProductWithMods &&
                    removeCartProduct(selectedCartProductWithMods);
                !!addProduct && addProduct(product, addToCartAnimation, false, false, modsToUse);
                showMessageAddedProduct();
            } else {
                if (!!handleSelectBundleItem) {
                    handleSelectBundleItem(product);
                }
            }

            !!setModificationProductIdToEdit && setModificationProductIdToEdit(null);
            setSelectedProductId(null);
            setSelectedProductModId(null);
            setOpenModifications(false);
            setSelectedModifications(null);
            setSelectedCartProductWithMods(null);
        } else if (!openModifications) {
            setOpenModifications(true);
            setSelectedProductModId(selectedProductId);
        }
    };

    const handleAddProductClick = () => {
        if (!hasAddons && !isBundleProduct) {
            !!addProduct && addProduct(product, addToCartAnimation);
            showMessageAddedProduct();
        } else {
            !!openProductPage && openProductPage(productAddons, isBundleProduct);
        }
    };

    const onHandleProductSelection = (productId: string) => {
        setSelectedProductId(isSelected ? null : productId);
        setSelectedProductModId(!!modifications ? productId : null);

        if (hasSingleMods && !!modifications) {
            setSelectedModifications(getPreSelectedSimpleMods(modifications));
        }

        setSelectedCartProductWithMods(null);
        !!setModificationProductIdToEdit && setModificationProductIdToEdit(null);

        isBundleItem &&
            !!handleMustChooseModifications &&
            handleMustChooseModifications(!hasSingleMods && !!modifications);
    };

    const onHandleSelectingModifications = (opt: OnlineModifications.SelectedModifications) => {
        setSelectedModifications(opt);
        if (isBundleItem && !!handleSelectModifications) {
            handleSelectModifications(opt as Modification, haveModificationsBeenSelected(opt));
        }
    };

    const { imageUrl, imageResizedUrls, name } = productToDisplay;
    const productImage = imageResizedUrls?.medium || imageUrl;

    const isSelectedProduct = isSelected || wasAdded || isBundleCategoryOpen || openModifications;

    const getBorderColor = () => {
        if (isOutOfStock) {
            return colors.expressLightGrey;
        }
        if (isSelectedProduct) {
            return colors.expressPrimary;
        }
        return colors.expressSecondaryDark;
    };

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    const canToggleSelected =
        !isOutOfStock && !isBundleCategoryOpen && !(isSimpleBundle && isSelected) && !openModifications;

    return (
        <Box
            h={isPortrait ? "380px" : "330px"}
            w={isPortrait ? "300px" : "260px"}
            bg={isOutOfStock ? "expressLightGrey" : colors.expressSecondary}
            borderRadius={"1.25rem"}
            position={"relative"}
            border={`3px solid`}
            borderColor={getBorderColor()}
            cursor={"pointer"}
            id={product.id}
            onClick={() => canToggleSelected && onHandleProductSelection(product.id)}
        >
            <ProductCardAnimation imageUrl={productImage} startAnimation={startAnimation} />

            <ProductImage
                productImage={productImage}
                isOutOfStock={isOutOfStock}
                productName={name}
                isPortrait={isPortrait}
            />
            {isBundleCategoryOpen && (
                <BundleCategory
                    product={product as MenuBundleProduct}
                    selectedBundleItemId={selectedBundleItemId}
                    isPortrait={isPortrait}
                    setSelectedBundleItemId={setSelectedBundleItemId}
                />
            )}

            {openModifications && !!selectedProductModId && (
                <ProductModifications
                    //TODO: here selected mods from this point - so function to set selected mods and then checks for the bundle item against the required
                    setModificationOption={onHandleSelectingModifications}
                    isPortrait={isPortrait}
                    isBundleItem={isBundleItem}
                    selectedModifications={selectedModifications}
                    modificationsToUse={modifications!}
                />
            )}

            {isOutOfStock && (
                <Box
                    position={"absolute"}
                    top={isPortrait ? "121px" : "110px"}
                    left={isPortrait ? "21px" : "8px"}
                    minH="52px"
                    w={isPortrait ? "262px" : "235px"}
                    color={"black"}
                    fontWeight={"600"}
                    fontSize={isPortrait ? "2xl" : "xl"}
                    bg="white"
                    borderRadius={"2.5rem"}
                    zIndex={1}
                    py={2}
                    px={8}
                    textAlign={"center"}
                >
                    {translate("tempOutOfStock")}
                </Box>
            )}
            {isSelectedProduct ? (
                <>
                    {!(wasAdded || isBundleCategoryOpen || openModifications) && (
                        <ExpressProductInformationBanner
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.stopPropagation();
                                onOpenDrawer("expressProductInformationDrawer", { product: product });
                            }}
                            border={"3px solid"}
                            borderColor={colors.expressPrimary}
                            position={"absolute"}
                            left={"-3px"}
                            top={isPortrait ? "164px" : "142px"}
                            bg={"white"}
                            height={isPortrait ? "64px" : "55px"}
                            width={isPortrait ? "72px" : "62px"}
                        />
                    )}
                    {isSimpleBundle ? (
                        <ProductCardFooterSimpleBundle
                            isBundleCategoryOpen={isBundleCategoryOpen}
                            handleAddProductClick={() => !wasAdded && handleAddSimpleBundleProduct()}
                            isEditingSimpleBundle={isEditingSimpleBundle}
                            {...footerProps}
                        />
                    ) : null}

                    {isSimpleProductWithManyMods || isBundleItemWithManyMods ? (
                        <ProductCardFooterSimpleMods
                            modificationIsOpen={openModifications}
                            isEditingMods={!!selectedProductModId && selectedModProductInEdit}
                            hasSingleMods={hasSingleMods}
                            handleAddProductClick={event => {
                                event.stopPropagation();
                                if (!wasAdded) {
                                    onHandleProductModifications(product.id);
                                }
                            }}
                            {...footerProps}
                        />
                    ) : null}

                    {isBundleItem && !isBundleItemWithManyMods ? (
                        <ProductCardFooterBundleItem handleAddProductClick={handleBundleItem} {...footerProps} />
                    ) : null}

                    {!(isSimpleBundle || isBundleItem || isSimpleProductWithManyMods) && (
                        <ProductCardFooterSelected
                            handleAddProductClick={() => !wasAdded && handleAddProductClick()}
                            {...footerProps}
                        />
                    )}
                </>
            ) : (
                <ProductCardFooter
                    productName={name}
                    productPrice={productPriceWithCurrency}
                    isOutOfStock={isOutOfStock}
                    isPortrait={isPortrait}
                />
            )}
        </Box>
    );
};
