import moment from "moment";

import {
    OnlineProducts,
    OrderProduct,
    RefBundleProduct,
    AddonsHashMapValue,
    OnlineProduct,
    OnlineModifications,
    MenuProduct,
    RefProduct,
    MenuBundleProduct,
    Modification,
    PriceType,
    CompanyLocale,
    CartProduct
} from "Types";
import { getPriceStringWithLocale } from "Utils/price/priceStringUtils";
import { CART_PRODUCT_TYPE, DisplayPriceModification, RequiredModificationState } from "./types";
import { isObjectEmpty } from "Utils";

/* Simple budnle product means a product with only 1 bundle product category, no addons,
    the limit of the category is 1 and max 5 products in the category */
export const checkIsSimpleBundleProduct = (refBundleProduct: RefBundleProduct, hasAddons: boolean) => {
    const productBundleCategories = refBundleProduct.bundleProductCategories;
    return (
        productBundleCategories.length === 1 &&
        !hasAddons &&
        productBundleCategories[0].limit === 1 &&
        productBundleCategories[0].refProductIdList.length <= 5
    );
};

/* Simple product is a refProduct with no addons and no modifications*/
export const checkIsSimpleProduct = (orderProduct: OrderProduct, productAddons: AddonsHashMapValue) => {
    const noModifications = orderProduct.modifications && !Object.keys(orderProduct.modifications)?.length;
    return !productAddons.length && !orderProduct.selectedBundleProductItems?.length && noModifications;
};

/**
 * [FUNCTION] - Check if the product is a simple product / used for upsell filter out simple products
 * @param product
 * @param hasAddons
 * @param refProducts
 * @returns
 */
export const isSimpleBundleProduct = (
    product: OnlineProduct,
    hasAddons: boolean,
    refProducts: { [id: string]: RefProduct }
) => {
    const isBundleProduct = "refBundleProduct" in product && !!product.refBundleProduct;
    if (!isBundleProduct) {
        return true;
    }
    if (isBundleProduct && product.refBundleProduct) {
        let isSimpleBundle = checkIsSimpleBundleProduct(product.refBundleProduct, hasAddons);
        if (isSimpleBundle) {
            /** If any of the simple bundle has a option that needs to have mods selected means it has to go to bundle creator
             * currently not doing that for upsell - so have to filter these out
             */
            const hasExtraMods = bundleItemsHaveExtraMods(
                product.refBundleProduct.bundleProductCategories[0].refProductIdList,
                refProducts
            );
            isSimpleBundle = !hasExtraMods;
        }
        return isSimpleBundle;
    }
};

export const getProductsByActiveHours = (onlineProducts: OnlineProducts): OnlineProducts =>
    onlineProducts.filter(
        product =>
            product?.activeHours.length === 0 ||
            product?.activeHours.some(active => active.dayOfWeek === moment().format("dddd").toLocaleUpperCase())
    );

/**
 * [FUNCTION] - Get required mods for the product and modifications to use
 * @param product
 * @returns
 */
export const getRequiredDefaultModifications = (
    product: OnlineProduct
): { modifications: OnlineModifications.ModificationsToUse | null; requiredMods: RequiredModificationState } => {
    if (product?.refProduct) {
        const modifications = getProductModificationsToUse(product);
        return {
            modifications,
            requiredMods: {
                sizeIsRequired: !!modifications?.sizes?.length,
                flavourIsRequired: !!modifications?.flavours?.length
            }
        };
    } else {
        return {
            modifications: null,
            requiredMods: {
                sizeIsRequired: false,
                flavourIsRequired: false
            }
        };
    }
};

/** Get modifications from the online product & get menu mod if it has be overriden  */
export const getProductModificationsToUse = (product: OnlineProduct): OnlineModifications.ModificationsToUse | null => {
    const modifications = product?.menuProduct?.modifications ?? product?.modifications;
    if (!modifications) return null;

    return convertModifications(modifications);
};

/**
 * [FUNCITON] Modification into a usable type from new "OnlineModification" namespace
 * @param modifications
 * @returns
 */
export const convertModifications = (modifications: Modification) =>
    Object.entries(modifications).reduce((acc, [name, options]) => {
        // Check if options is an array, is not empty, and does not include the key
        if (Array.isArray(options) && options.length > 0 && !options.some(option => option.name === name)) {
            acc[name as OnlineModifications.ModificationKeys] = options;
        }
        return acc;
    }, {} as OnlineModifications.ModificationsToUse);

/**
 * [FUNCION] Pass in Online product with type guard for a ref product - if it doesn't have the props in the object then false
 * @param product
 * @returns
 */
export const isMenuProduct = (
    product: OnlineProduct
): product is OnlineProduct & { menuProduct: MenuProduct; refProduct: RefProduct } => {
    return !!product.menuProduct && !!product.refProduct;
};

/**
 * [FUNCION] Pass in Online product with type guard for a ref bundle product - if it doesn't have the props in the object then false
 * @param product
 * @returns
 */
export const isMenuBundleProduct = (
    product: OnlineProduct
): product is OnlineProduct & { menuBundleProduct: MenuBundleProduct; refBundleProduct: RefBundleProduct } => {
    return !!product.menuBundleProduct && !!product.refBundleProduct;
};

/**
 * [FUNCTION] - Checks menu bundle item mods - if the mods on the bundle items are simple then return false
 * @param product
 * @returns
 */
export const bundleItemsHaveExtraMods = (refProductIds: string[], refProductHashMap: { [id: string]: RefProduct }) => {
    const productRefs = refProductIds.map(id => refProductHashMap[id]).filter(prod => !!prod);

    if (!!productRefs) {
        const bundleItemsHaveOneModOrZeroMods = productRefs.every(ref => {
            if (!ref.modifications) {
                return true;
            }
            const hasNoFlavoursOrOne =
                !ref?.modifications?.flavours ||
                (Array.isArray(ref?.modifications?.flavours) && ref?.modifications?.flavours?.length <= 1);
            const hasNoSizesOrOne =
                !ref?.modifications?.sizes ||
                (Array.isArray(ref?.modifications?.sizes) && ref?.modifications?.sizes?.length <= 1);
            return hasNoFlavoursOrOne && hasNoSizesOrOne;
        });
        return !bundleItemsHaveOneModOrZeroMods;
    }
    return false;
};

/**
 * [FUNCTION] CHECK the mods to use object for simple mods inorder to have a default
 * @param modifications
 * @returns
 */
export const productHasSimpleMods = (modifications: OnlineModifications.ModificationsToUse) => {
    const hasNoneOrZeroSizeMods = productHasSimpleSizeMod(modifications);
    const hasNoneOrZeroFlavourMods = productHasSimpleFlavourMod(modifications);
    return hasNoneOrZeroSizeMods && hasNoneOrZeroFlavourMods;
};

/**
 * [FUNCTION] - Check if the product has simple size mods
 * @param modifications
 * @returns
 */
export const productHasSimpleSizeMod = (modifications: OnlineModifications.ModificationsToUse) => {
    const hasNoneOrZeroSizeMods =
        !modifications?.sizes || (Array.isArray(modifications?.sizes) && modifications?.sizes?.length <= 1);
    return hasNoneOrZeroSizeMods;
};

/**
 * [FUNCTION] - Check if the product has simple flavours mods
 * @param modifications
 * @returns
 */
export const productHasSimpleFlavourMod = (modifications: OnlineModifications.ModificationsToUse) => {
    const hasNoneOrZeroFlavourMods =
        !modifications?.flavours || (Array.isArray(modifications?.flavours) && modifications?.flavours?.length <= 1);
    return hasNoneOrZeroFlavourMods;
};

/**
 * [FUNCTION] - Get simple pre selected mods
 * @param modifications
 * @returns
 */
export const getPreSelectedSimpleMods = (
    modifications: OnlineModifications.ModificationsToUse
): OnlineModifications.SelectedModifications => {
    const { sizes, flavours } = modifications;
    const sizeMod = sizes?.length === 1 ? sizes?.at(0) : null;
    const flavourMod = flavours?.length === 1 ? flavours?.at(0) : null;
    return {
        ...(!!sizeMod && { ["sizes"]: sizeMod }),
        ...(!!flavourMod && { ["flavours"]: flavourMod })
    };
};

/**
 * [FUNCTION] Check if the modifcations have price
 * @param modifications
 * @returns
 */
export const productModificationsHavePrice = (modifications: OnlineModifications.ModificationsToUse) => {
    return (
        modifications?.flavours?.some(val => val?.price > 0) ||
        modifications?.sizes?.some(val => val?.price > 0) ||
        false
    );
};

/**
 * [FUNCTION] Check if the modifications have add on price
 * @param modifications
 * @returns
 */
export const productModificationsHaveAddonPrice = (modifications: OnlineModifications.ModificationsToUse) => {
    return (
        modifications?.flavours?.some(val => val?.addonPrice > 0) ||
        modifications?.sizes?.some(val => val?.addonPrice > 0) ||
        false
    );
};

/**
 * [FUNCTION] - GET Min price from mods
 * @param modifications
 * @returns
 */
export const getMinModifcationPrice = (modifications: OnlineModifications.ModificationsToUse) => {
    const minFlavourPrices = modifications?.flavours?.map(val => (val.price ?? 0) + (val.addonPrice ?? 0)) ?? [0];
    const minSizePrices = modifications?.sizes?.map(val => (val.price ?? 0) + (val.addonPrice ?? 0)) ?? [0];
    return Math.min(...minSizePrices) + Math.min(...minFlavourPrices);
};

/**
 * [FUNCTION] - Get price to display on card for modification + product
 * @param defaultPrice
 * @param priceType
 * @param modifications
 * @param companyLocale
 * @returns
 */
export const getMenuProductPriceWithModifications = (
    defaultPrice: number,
    priceType: PriceType,
    isBundleItem: boolean,
    modifications: OnlineModifications.ModificationsToUse,
    companyLocale: CompanyLocale
): { hasStartingPrice: boolean; priceText: string; mustChooseOptions: boolean } => {
    const modsHavePrice = productModificationsHavePrice(modifications);
    const modsHaveAddonPrice = productModificationsHaveAddonPrice(modifications);
    const isSingleMod = productHasSimpleMods(modifications);
    const minModPrice = getMinModifcationPrice(modifications);

    if (modsHavePrice && isSingleMod) {
        return {
            hasStartingPrice: false,
            priceText: getPriceStringWithLocale(minModPrice, priceType, companyLocale),
            mustChooseOptions: !isSingleMod
        };
    } else if ((modsHavePrice || modsHaveAddonPrice) && !isSingleMod) {
        const lowestPrice = (modsHavePrice ? 0 : defaultPrice) + minModPrice;
        const isBundleItemWithAddonPrice = isBundleItem && modsHaveAddonPrice;
        const isBundleItemWithModPrice = isBundleItem && modsHavePrice;
        if (isBundleItemWithAddonPrice) {
            const isNegativePrice = minModPrice < 0;
            return {
                hasStartingPrice: modsHaveAddonPrice,
                priceText: `${!isNegativePrice ? "+" : ""} ${getPriceStringWithLocale(minModPrice, priceType, companyLocale)}`,
                mustChooseOptions: !isSingleMod
            };
        } else if (isBundleItemWithModPrice) {
            return {
                hasStartingPrice: false,
                priceText: "",
                mustChooseOptions: !isSingleMod
            };
        }
        return {
            hasStartingPrice: lowestPrice !== 0,
            priceText: lowestPrice === 0 ? "" : getPriceStringWithLocale(lowestPrice, priceType, companyLocale),
            mustChooseOptions: !isSingleMod
        };
    } else {
        const priceToUse = defaultPrice + minModPrice;
        if (modsHaveAddonPrice && isSingleMod && isBundleItem && !modsHavePrice) {
            const isNegativePrice = minModPrice < 0;
            return {
                hasStartingPrice: false,
                priceText: `${!isNegativePrice ? "+" : ""} ${getPriceStringWithLocale(minModPrice, priceType, companyLocale)}`,
                mustChooseOptions: !isSingleMod
            };
        }

        /** Will make sure that the default price (above 0) is not displayed with the 0kr hack */
        const isEmptyModificationHack =
            isSingleMod && !modsHaveAddonPrice && !modsHavePrice && isBundleItem && minModPrice === 0;

        return {
            hasStartingPrice: false,
            priceText: !isEmptyModificationHack ? getPriceStringWithLocale(priceToUse, priceType, companyLocale) : "",
            mustChooseOptions: !isSingleMod
        };
    }
};

/**
 * [FUNCTION] - Check if the product has modifications - used for edit mode
 * @param product
 * @returns
 */
export const checkEditModifications = (
    product: OnlineProduct
): { hasModifications: boolean; isSingleMods: boolean } => {
    const modifications = product?.refProduct && getProductModificationsToUse(product);

    if (!modifications) return { hasModifications: false, isSingleMods: false };

    const isSingleMods = productHasSimpleMods(modifications);
    if (isSingleMods) return { hasModifications: false, isSingleMods: true };

    return { hasModifications: true, isSingleMods: false };
};

/**
 * [FUNCTION] - Get the price for the checkout summary
 * @param product
 */
export const getCheckoutSummaryModificationPrices = (product: CartProduct): DisplayPriceModification => {
    const modifications = product?.orderProduct?.modifications;

    if (!modifications)
        return {
            hasModifications: false,
            isAddonPriceModification: false,
            isPriceModification: false,
            priceModification: null
        };

    const { sizes, flavours } = modifications as OnlineModifications.SelectedModifications;
    const isModPrice = (sizes?.price ?? 0) !== 0 || (flavours?.price ?? 0) !== 0;
    const isAddonPrice = (sizes?.addonPrice ?? 0) !== 0 || (flavours?.addonPrice ?? 0) !== 0;
    const modPrice = (sizes?.price ?? 0) + (flavours?.price ?? 0);

    return {
        hasModifications: isModPrice || isAddonPrice,
        isPriceModification: isModPrice,
        isAddonPriceModification: isAddonPrice,
        priceModification: isModPrice ? modPrice * product.orderProduct.quantity : null
    };
};

/**
 * [FUNCITON] - TYPE OF CART PRODUCT
 * @param cartProduct
 * @param hasAddons
 * @returns
 */
export const getCartProductType = (cartProduct: CartProduct, hasAddons: boolean = false): CART_PRODUCT_TYPE => {

    /** 1. Check type of product */
    const isSimpleMenuProduct = !!cartProduct?.menuProduct?.refProduct;
    const isBundleMenuProduct = !!cartProduct?.menuBundleProduct?.refBundleProduct;

    const hasModifications =
        !!cartProduct.orderProduct?.modifications && !isObjectEmpty(cartProduct.orderProduct?.modifications ?? {});

    if (!hasModifications && !hasAddons && isSimpleMenuProduct) {
        /** If no mod and no add ons and is simple menu product */
        return CART_PRODUCT_TYPE.SIMPLE_MENU_PRODUCT; // No Modify needed
    }

    if (isBundleMenuProduct) {
        /** If bundle product no need to check addons just if it is simple or not */
        const isSimpleBundle = checkIsSimpleBundleProduct(cartProduct.menuBundleProduct?.refBundleProduct!, hasAddons);
        return isSimpleBundle ? CART_PRODUCT_TYPE.SIMPLE_BUNDLE_PRODUCT : CART_PRODUCT_TYPE.BUNDLE_MENU_PRODUCT;
    }

    if (hasAddons && !hasModifications && isSimpleMenuProduct) {
        return CART_PRODUCT_TYPE.MENU_PRODUCT_ADDONS; // need modify
    }

    if (hasModifications && isSimpleMenuProduct) {
        const modsToUse = convertModifications(cartProduct?.menuProduct?.refProduct?.modifications!);
        const hasSimpleModifications = productHasSimpleMods(modsToUse);
        return hasSimpleModifications
            ? CART_PRODUCT_TYPE.SIMPLE_MODIFIED_PRODUCT
            : CART_PRODUCT_TYPE.MODIFIED_MENU_PRODUCT;
    }
    return CART_PRODUCT_TYPE.SIMPLE_MENU_PRODUCT;
};
