import React, { useState } from "react";
import { FaCommentAlt } from "@react-icons/all-files/fa/FaCommentAlt";

import { ExpressButton } from "./ExpressButton";
import { Flex, PseudoBox, Box, Textarea } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import { getTextColor } from "Utils";

type ModalProps = {
    modalContent: {
        comment: string;
        addCommentAndCloseModal: (comment: string) => void;
    };

    closeModal: (modal: string) => void;
};

export const ExpressCommentModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    const { comment, addCommentAndCloseModal } = modalContent;
    
    const [currentComment, setComment] = useState(comment ?? "");

    const {
        colors: { expressPrimary, expressSecondary }
    } = useTheme();

    const { translate } = useLanguage();
    const focusProps = {
        _focus: {
            outline: "none"
        }
    };

    const oneLineComment = currentComment.length < 60 && currentComment.split("\n").length <= 1;
    return (
        <Modal open={true} placement="center" size="xxl" borderRadius={"1.25rem"}>
            <ModalHeader>
                <ModalCloseBtn
                    onClick={() => closeModal(modalNames.EXPRESS_COMMENT_MODAL)}
                    bg={expressPrimary}
                    h={"32px"}
                    w={"32px"}
                    borderRadius={"100%"}
                    color={getTextColor(expressPrimary)}
                    top={"24px"}
                    right={"24px"}
                />
            </ModalHeader>
            <ModalBody w={"1016px"} h={"266px"} p={8} m="auto">
                <Flex justifyContent={"space-between"} alignItems={"center"} w={"952px"} mb={6}>
                    <PseudoBox
                        as="button"
                        type="button"
                        backgroundColor={expressPrimary}
                        h={"64px"}
                        w={"64px"}
                        borderRadius={"100%"}
                        border={"none"}
                    >
                        <Box
                            as={FaCommentAlt}
                            size={"30px"}
                            color={getTextColor(expressPrimary)}
                            verticalAlign={"text-top"}
                        />
                    </PseudoBox>
                    <Flex
                        bg={expressSecondary}
                        color={getTextColor(expressSecondary)}
                        alignItems={"center"}
                        borderRadius={"20px"}
                        px={10}
                        fontSize={"xl"}
                        w={"864px"}
                        minH={oneLineComment ? "94px" : "120px"}
                    >
                        <Textarea
                            value={currentComment}
                            onChange={({ target }: any) => setComment(target.value)}
                            placeholder={translate("addNewComment")}
                            resize={"none"}
                            border={"none"}
                            bg={"transparent"}
                            w={"100%"}
                            minH={oneLineComment ? "80px" : "100px"}
                            lineHeight={oneLineComment ? "60px" : "normal"}
                            fontSize={"xl"}
                            color={getTextColor(expressSecondary)}
                            {...focusProps}
                        />
                    </Flex>
                </Flex>
                <Flex justifyContent={"flex-end"}>
                    <ExpressButton
                        themeColor={expressPrimary}
                        size={"lg"}
                        variant="outline"
                        mr={"6"}
                        w={"258px"}
                        children={translate("close")}
                        onClick={() => closeModal(modalNames.EXPRESS_COMMENT_MODAL)}
                    />
                    <ExpressButton
                        themeColor={expressPrimary}
                        size={"lg"}
                        variant="solid"
                        w={"258px"}
                        children={translate("save")}
                        onClick={() => addCommentAndCloseModal(currentComment)}
                    />
                </Flex>
            </ModalBody>
        </Modal>
    );
};
