import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { Box, Flex, Image } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { ExpressButton } from "../shared/ExpressButton";
import { ReceiptReminder } from "../shared/ReceiptReminder";
import { useQoplaStore } from "Stores";
import { formatShopUrlName, getTextColor } from "Utils";
import { ReceiptPrintType } from "../../Utils/types";

const NEXT_CUSTOMER_TIMEOUT = 8000;

const growingWidth = keyframes`
    0% { width: 45px; 
        border-top-right-radius: 0; 
        border-bottom-right-radius: 0;} 
    100%  {  width: 382px; 
         border-radius: 5rem;
        }
`;

// @ts-ignore
const BoxAnimation = styled(props => <Box {...props} />)`
    animation: ${NEXT_CUSTOMER_TIMEOUT / 1000}s ${growingWidth} ease-out forwards;
    animation-delay: 1.5s;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5rem;
    height: 84px;
`;

type Props = {
    orderNumber: number;
    receiptPrintType: ReceiptPrintType;
    goToStartScreen: () => void;
};

export const ExpressOrderConfirmation: React.FC<Props> = ({ orderNumber, receiptPrintType, goToStartScreen }) => {
    const { translate } = useLanguage();
    const {
        colors: {
            expressPrimary,
            expressSecondary,
            expressTextColor,

            expressMediumGrey,
            expressLightGrey
        },
        orientation: { isPortrait }
    } = useTheme();

    const { selectedShop } = useQoplaStore();

    useEffect(() => {
        const timer = setTimeout(() => {
            goToStartScreen();
        }, NEXT_CUSTOMER_TIMEOUT);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const formattedShopUrlName = formatShopUrlName(selectedShop?.name ?? "");
    const url = `${window.location.origin}/restaurant/${formattedShopUrlName}/${selectedShop?.publicId}`;

    const textColorChange = keyframes`
        0% { color: ${expressTextColor}; }
        100% { color: white; } 
    `;

    const ChangingText = styled.span`
        animation: 8s ${textColorChange} ease-out forwards;
        animation-delay: 1.5s;
    `;

    const isOrderNumberSlip = receiptPrintType === ReceiptPrintType.ORDER_NUMBER;

    return (
        <Flex pt="4rem" direction="column" alignItems="center" w="100%">
            <Box fontSize={"4xl"} fontWeight={"600"} mb={8}>
                {isOrderNumberSlip ? translate("receiptReminder") : translate("yourOrderReceipt")}
            </Box>
            <Box
                position={"relative"}
                w={isPortrait ? "464px" : "390px"}
                h={isPortrait ? "464px" : "390px"}
                overflow={"hidden"}
                mb={16}
                borderBottom={`5px solid ${expressMediumGrey}`}
            >
                <ReceiptReminder orderNumber={orderNumber} />
            </Box>
            {isPortrait && (
                <>
                    <Box fontSize={"2xl"} textAlign={"center"} w="604px" mb={8}>
                        {translate("nextTimeExpressText")}
                    </Box>
                    <Image
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${url}`}
                        h={"188px"}
                        w={"188px"}
                        mb={16}
                    />
                </>
            )}
            <Box position={"relative"}>
                <BoxAnimation backgroundColor={expressPrimary} />
                <ExpressButton
                    themeColor={expressPrimary}
                    size={"lg"}
                    w={"382px"}
                    variant="outline"
                    onClick={goToStartScreen}
                    background={"none"}
                >
                    <ChangingText>{translate("nextCustomer")}</ChangingText>
                </ExpressButton>
            </Box>
        </Flex>
    );
};
