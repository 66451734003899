import React from "react";
import styled from "styled-components";

import { BaseBoxProps, Box } from "Atoms";
import { useTheme } from "ThemeProvider";

const AnimatedReceipt = styled((props: { isPortrait: boolean } & BaseBoxProps) => <Box {...props} />)`
    @keyframes print000 {
        0% {
            transform: translateY(99%);
        }
        100% {
            transform: translateY(
                calc(
                    100% - ${(props: { isPortrait: boolean } & BaseBoxProps) => (props.isPortrait ? "464px" : "390px")}
                )
            );
        }
    }
    animation: 1s print000 linear forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    --mask: radial-gradient(12.83px at 50% 17.6px, #000 99%, #0000 101%) calc(50% - 22px) 0/44px 100%,
        radial-gradient(12.83px at 50% -6.6px, #0000 99%, #000 101%) 50% 11px/44px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    padding-top: 1rem;
`;

type Props = {
    orderNumber: number;
};

export const ReceiptReminder: React.FC<Props> = ({ orderNumber }) => {
    const {
        orientation: { isPortrait },
        colors: { expressLightGrey, expressTextColor }
    } = useTheme();

    const orderNumberFontSize = isPortrait
        ? orderNumber.toString().length <= 2
            ? "12.5rem"
            : "8.5rem"
        : orderNumber.toString().length <= 2
        ? "8.75rem"
        : "6.75rem";

    return (
        <Box
            position="absolute"
            top={"0"}
            h={"fit-content"}
            w={"100%"}
            left={"0"}
            transform={isPortrait ? "translateX(8%)" : "translateX(8%)"}
        >
            <AnimatedReceipt
                h={isPortrait ? "464px" : "390px"}
                w={isPortrait ? "389px" : "327px"}
                bg={expressLightGrey}
                flexDirection="column"
                isPortrait={isPortrait}
            >
                <Box
                    fontWeight={"600"}
                    fontSize={isPortrait ? "4xl" : "2xl"}
                    color={expressTextColor}
                    textAlign={"center"}
                    px={4}
                    pt={6}
                >
                    {`Order #`}
                </Box>
                <Box fontSize={orderNumberFontSize} fontWeight={"600"} color={expressTextColor}>
                    {orderNumber}
                </Box>
            </AnimatedReceipt>
        </Box>
    );
};
